// import Cookies from 'universal-cookie';
//import {environment} from '../environment.js';
//import { authHeader } from '../_helpers';
//import {Md5} from 'ts-md5/dist/md5';
// const cookies = new Cookies();
const apiURL = 'https://api.digitalaakriti.com/api/';
// const ArgentAPIurl = "https://v1.artamlabs.com/olv/mobapp/";
export const serverUtilities = {
    login,
    addlead,
    validateZeroBounceEmail,
    getBlog,
    dopaymentnow
}; 

// function getCurrentTime(){
//   return Math.round(new Date().getTime()/1000);
// }
async function getBlog(slug){
  console.log(slug);
  const URI ="https://v1.artamlabs.com/olv/mobapp/user/getBlog/"+slug;
   const requestOptions = {
        method: 'GET',
        headers: {}
    };
    return fetch(URI, requestOptions)
    .then(handleResponse)
    .then(res => {
        console.log(res);
        return res;
    });
}
function validateZeroBounceEmail(email){
    let zeroBounceKey = '65abd2a8257f4e938d434ad13004be3a';
    
    let ip = "99.123.12.122";

    let URI = "https://api.zerobounce.net/v2/validate?api_key="+zeroBounceKey+"&email="+email+"&ip_address="+ip;
    const requestOptions = {
        method: 'GET',
        headers: {}
    };
    return fetch(URI, requestOptions)
    .then(handleResponse)
    .then(res => {
        console.log(res);
        

        return res;
    });

}
function addlead(fromPage, fname, lname, email, phone, msg, refurl, emailStatus) {
     
    let URI = apiURL+"captureLead.php";    

    

    //add time
    //add hash
    //enable complete validation 




    //console.log(name);
    console.log(refurl);
    let jsonData = JSON.stringify({ 
        name: fname  + " " + (lname || ""),
        phone: phone,
        email: email, 
        projectName: 'EISolutions-website',
        groupId: 0,
        projectId: '',
        refurl: refurl,
        source: fromPage,
        medium: '',
        campaign: '',
        addgroup: '',
        msg: msg,
        // timeExp: '1000000',
        // time: '',
        // hash: '',
        // emailStatus: emailStatus
    })
    let body = jsonData;    
    console.log(body);
    const requestOptions = {
        method: 'POST',
        headers: {},
        body: body
    };
    return fetch(URI, requestOptions)
        .then(handleResponse)
        .then(user => {
            console.log(user);
            
            
   
            return user;
        });

}
function login(mode, username, password) {
     
    let URI = apiURL+"admin/adminWebL/32423423";
    
    let jsonData = JSON.stringify({ 
        signin_mode:mode,
        email: username, 
        phone: '',
        password: password,
        model:'0',
        fcmId:'',
        accessFrom:'BROW',
        main_group: "120990",
        timeExp: '20',
        time: '',
        hash: '',
    })
    let body = jsonData;
    console.log(body);
    const requestOptions = {
        method: 'POST',
        headers: {},
        body: body
    };
    return fetch(URI, requestOptions)
        .then(handleResponse)
        .then(user => {
            console.log(user);
            return user;
        });
}

// payment gateway auropay integration 

function dopaymentnow() {
     
    // let jsonData = JSON.stringify({ 
    //     name: fname  + " " + (lname || ""),
    //     phone: phone,
    //     email: email, 
    //     projectName: 'EISolutions-website',
    //     groupId: 0,
    //     projectId: '',
    //     refurl: refurl,
    //     source: fromPage,
    //     medium: '',
    //     campaign: '',
    //     addgroup: '',
    //     msg: msg,
    //     // timeExp: '1000000',
    //     // time: '',
    //     // hash: '',
    //     // emailStatus: emailStatus
    // })
    // let body = jsonData;    
    // console.log(body);

    var myHeaders = new Headers();
    myHeaders.append("x-version", "1.0");
    myHeaders.append("x-access-key", "067F247FE9FDEA1437A76F41A97E43DC");
    myHeaders.append("x-secret-key", "sFahPtcvwqBgWhj3szr1VRqH+BflbMvVA7NZ0Bcd6Ps=");
    const date = new Date().getTime();
    const now = new Date();

    // Add 5 hours to the current time
    const expiryTime = new Date(now.getTime() + 5 * 60 * 60 * 1000);
    // console.log("time ", date);
    const newtitle = "eis_"+date.toString();

    var raw = "{\n  \"expireOn\": \"12-12-2023 15:41:15\",\n  \"amount\": 100,\n  \"Customers\": [\n    {\n      \"firstName\": \"Jigar Shah\",\n      \"lastName\": \" \",\n      \"phone\": \"9971200884\",\n      \"email\": \"test@aurionpro.com\"\n    }\n  ],\n  \"invoiceNumber\": \"\",\n  \"enableMultiplePayment\": false,\n  \"CallbackParameters\": {\n    \"CallbackApiUrl\": \"https://eisolutions.in/\",\n    \"ReferenceNo\": \"BUS_124abc\"\n  },\n  \"shortDescription\": \"\",\n  \"title\": \"eis\",\n  \"Settings\": {\n    \"displaySummary\": false\n  },\n  \"ResponseType\": 1,\n  \"paymentDescription\": \"\"\n}";


    // Step 3: Parse the JSON string into an object
    let jsonObject = JSON.parse(raw);

    // Step 4: Update the title field
    jsonObject.title = newtitle;
    jsonObject.expireOn = expiryTime;
    // let CallbackjsonObject = JSON.parse(jsonObject.CallbackParameters);
    // CallbackjsonObject.ReferenceNo = newtitle;
    // let updatedCallback = jsonObject.stringify(jsonObject.CallbackjsonObject, null, 2);
    // Convert the object back to a JSON string if needed
    let updatedRaw = JSON.stringify(jsonObject, null, 2);
    console.log("upraw ", updatedRaw);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: updatedRaw,
        redirect: 'follow'
    };

    return fetch("https://api.uat.auropay.net/api/paymentlinks", requestOptions)
    .then(response => response.text())
    .then(result => {console.log(result); return result})
    .catch(error => console.log('error', error));

}
 function logout() {
    // remove user from local storage to log user out
    //localStorage.removeItem(environment.LocalstorageKey);
   }
  function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}