import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainBanner from "components/hero/AurumHeroContent.js";
import Stats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImage.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
import caseStudyProfileImage from "images/lp_anuj_k_acharya.png"

const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-brand-500`;
const HighlightedText = tw.span`text-brand-500 `;

const BoldTag = styled.span`
   ${tw`text-textBlack font-bold`}
`;
export default ({
 title = "Simplified On-Boarding",
 highlightedtext = "Get Started.",
 heading = "Easy to",
 CheckIcon = Checkicon,
}) => { 
  return (
    <AnimationRevealPage>
      <MainBanner roundedHeaderButton={true} 
        mainTitle="Say Hello to a Better Way to Deliver Digital Content" 
        subTitle="Agile & Secure Platform build to Deliver Content at Enterprise Scale. Streamline and Modernise Your Digital Content Experience."
        buttonText="Let's Talk"
        buttonUrl="https://wa.me/918826622806/?text=Hi, I would like to GROW my Business with Aurum Content Delivery Platform."
        imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/content_delivery_home_new.gif"
        showClient= {true}
        />
      <CaseStudy
          imageSrc={caseStudyLogoImage}
          testimonialText = '“To overcome the Constraints of COVID 19, Aurum designed an Interactive Streaming Platform that combines the very best aspects of in-person and digital language training. Instructors and users benefit from an engaging format boosted with Aurum Platform Capabilities and Global Video Streaming at very low latency.”'
          profileImageSrc={caseStudyProfileImage}
          customerName="Anuj K Acharya"
          customerTitle="Founder, CEO"
          buttonUrl= ""
        />
      <Stats/>
      <OurExpertise
       description = {
        <>
          Deliver all most all types of contents on <BoldTag>Mobile Devices</BoldTag> - Android, iOS and Mobile Browsers, <BoldTag>Desktops & Laptops</BoldTag> - Windows App, MAC App and Browser Access. 
        </>
      }
      />
      <ContactDetails heading="First All in One Content Delivery Platform" subheading="" description="Everything You Need to Deliver & Monetise Content "/>
      <FeatureWithSteps
        subheading={<SubheadingSteps>{title}</SubheadingSteps>}
        heading={
          <>
            {heading} <HighlightedText>{highlightedtext}</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/easy_onboarding.gif"
        steps={
            [
              { 
                mainIcon:"",
                heading: "Unify Content",
                description: "Manage & Structure Content from a Single Library" 
              },

              {
                mainIcon:"",
                heading:"Integrate",
                description: "Deliver Content through Aurum Apps or Integrate APIs in Custom Apps."
              },

              {  
                mainIcon:"",
                heading: "Ready to GO",
                description: "Easy to Use Console, Platform Documentations and Pay as You Go Pricing. "
              }
            ]
          }
      />
      
      <TrackRecord 
      textOnLeft={true}
      heading={
        <>
          Why <HighlightedText>Aurum</HighlightedText> is the Best Choice
        </>
      } description = 
        {
          <>
            Its the New Front Door for the Brands to Deliver Content Across Globe on Almost All Devices. Aurum makes it easy for the Content Owners and Developers to Build Faster and Manage Delivery at Scale.<p>&nbsp;</p><p>It is the easiest and fastest way to Streamline, Modernise and Monetise Content. <BoldTag>Let’s build better digital experiences together.</BoldTag></p>
          </>

        } imageSrc = "https://ik.imagekit.io/zjste1wkulv/tr:q-50/why_aurum_2.png"
      subheading = "The Modern Content Delivery & Monetisation Platform"
      />
      <Testimonials description = {
        <>
          We work with you from start to finish. We view ourselves as an extension of your team, and as your partner, we pride ourselves in being fully engaged with your company to help you find opportunities for growth. <BoldTag>The Reason why Content Owners Love Aurum</BoldTag> 
        </>
      } imageSrc = "https://ik.imagekit.io/zjste1wkulv/tr:q-90/testimonials2.jpg"
        />
      <Faqs
      textOnLeft={true}
      />
      <PreFooter fromPage = "Content Delivery Platform" formheading = "Streamlining & Managing Content Storage and Distribution Amplifies Team Efficiency and Sales" formsubheading = "Book Our Free Discussion Session Today - " heightlightText="The Benefits of Using a Structured Content Distribution Platform"/>
      <Footer />
    </AnimationRevealPage>
  );
};
