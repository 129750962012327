import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/AurumHeroContent.js";
import FeaturesLive from "components/features/LiveThreeColWithTopImage.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import VideoContentImage from "images/aurum_Icons/aurum-video-library.svg";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import DevicesImage from "images/aurum_Icons/aurum-devicesImage.svg";
import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
import caseStudyProfileImage from "images/lp_anuj_k_acharya.png"
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import DesignIllustration from "images/aurum_Icons/heroImg.svg";
import { components } from "ComponentRenderer.js";
import { Helmet } from 'react-helmet';
export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  }) => {
  const Subheading = tw.p`tracking-widest text-textBlack text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;

  return (
    <>
      <Helmet
      
      title='Live Video Streaming Services | Aurum Labs'
      
      meta={[
        {
          name: 'description',
          content: 'Live Video Streaming Platform for All Kinds of Videos',
        },
        {
          property: `og:title`,
          content: 'Live Video Streaming Services | Aurum Labs',
        },
        {
          property: `og:description`,
          content: 'Live Video Streaming Platform for All Kinds of Videos',
        }]}
    />
    
      <Hero roundedHeaderButton={true} 
        mainTitle="Live Video Streaming built to Scale, Reliably and Securely" 
        subTitle="Create immersive, Engaging Live Sessions & Events for Users, No Matter Where They Are"
        buttonText="Lets Connect !!"
        buttonUrl="https://wa.me/918826622806/?text=Hi, We would like to live stream videos with Aurum"
        imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-70/live_video_home.gif"
        showClient= {true}
      />
      <AnimationRevealPage>
        
       
        <CaseStudy
          imageSrc={caseStudyLogoImage}
          testimonialText = '“To overcome the Constraints of COVID 19, Aurum designed an Interactive Streaming Platform that combines the very best aspects of in-person and digital language training. Instructors and users benefit from an engaging format boosted with Aurum Platform Capabilities and Global Video Streaming at very low latency.”'
          profileImageSrc={caseStudyProfileImage}
          customerName="Anuj K Acharya"
          customerTitle="Founder, CEO"
          buttonUrl= ""
        />
        <FeaturesLive
          subheading={<Subheading>Platform that makes Planning, Delivery and Engagement Easier than Ever</Subheading>}

          heading={
            <>
              Interactive Live Streaming Platform, Endless Potential
            </>
          }
        />
        <MainFeature
          primaryButtonText="Let's Talk"
          primaryButtonUrl = "https://wa.me/918826622806/?text=Hi, We would like to live stream videos with Aurum"
          heading="Take the Stress Out Of Live Streaming & Management"
          description="Live Video Streaming seems complicated, but it does not have to be. Aurum takes care of the entire streaming and distribution process, enabling you to focus only on the Content.<p><b>Playback on Almost All Devices</b><br />Cost - effective streaming  to any device and network, with optional adaptive bitrates.</p><p><b>Encoder Flexibility</b><br />Use the Encoder of Your Choice - OBS, Xsplit etc. Simply push the RTMP to Aurum servers and let us do the rest.</p><p><b>Achieve Global Scale</b><br />Broadcast your streams to Aurum Apps, Custom Apps, Web, Social Networks simultaneously.</p><p><b>Benefit from Aurum Expertise</b><br />Use Aurum expertise to setup cost effective high quality studios, hardware setups and low cost high ROI solutions.</p>"
          subheading="Features & Functionality "
          textOnLeft={true}
          imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-70/live_video_stress.gif"
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText="Let's Talk"
          primaryButtonUrl = "https://wa.me/918826622806/?text=Hi, We would like to live stream videos with Aurum"
          heading="Live Stream Recording"
          description="<p><b>Archive the live streams</b><br />the feature allows you to create recorded (video on demand / VOD) assets from your live streams instantly and easily.</p><p><b>More Power to Users</b><br />Give the powers to your viewers to play, pause and viewing content at their convenience, with complete content security and controlled viewing hours (if needed).</p><p><b>Expand your Viewership</b><br />Increase viewership numbers and boost the performance of your content by offering recordings of your live events for playback later. Stream VOD assets to any player or device, using the same stream configuration and security settings as were used in your live stream.</p>"
          subheading="Stream Live Now, Expand your Audience Later"
          imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-70/live_video_record_upload.jpg"
          imageBorder={false}
          imageDecoratorBlob={false}
          textOnLeft={false}
        />
        
        <MainFeature
          primaryButtonText="Let's Connect"
          primaryButtonUrl = "https://wa.me/918826622806/?text=Hi, We would like to live stream videos with Aurum"
          heading="Live Stream on Apps & Devices that Users Love"
          description="Impress Users with availability of the Content in their choice of devices on <b>Aurum Apps</b> or integrate the content from Aurum Content Library to your existing Web & Apps easily with APIs.<p>Deliver Live Sessions on Laptops with Complete Content <b>Security from Downloads and Screen Capture</b>, enabling much more user engagement and satisfaction in long duration sessions.</p>"
          subheading="Optimise Your User Experience to the Fullest"
          textOnLeft={true}
          imageSrc={DevicesImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText="Let's Connect"
          primaryButtonUrl = "https://wa.me/918826622806/?text=Hi, We would like to live stream videos with Aurum"
          heading="Why Aurum is the Best Live Streaming Solution for Me ??"
          description="<p>Aurum Live Streaming solutions offers robust infrastructure and auto scaling capabilities along with <link to page>Apps for Web and Mobiles</link to page> for content delivery.</p><p>Choose Aurum if You …<ul><li>Need one stop solution for autoscaling and reach any-size audience.</li><li>Need to go Live Quickly with Standard or Custom Apps.</li><li>Need Content security in every case.</li><li>Need to deliver content dynamically and flexibly.</li><li>Need a cloud based solution with pay-as-you go pricing rather than monthly commitments.</li><li>Don’t want to invest huge and manage softwares.</li>"
          subheading=""
          imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-80/aurum_apps_chose_us.gif"
          imageBorder={false}
          imageDecoratorBlob={false}
          textOnLeft={false}
        />
        <FAQ
          subheading={<Subheading></Subheading>}
          description={'Platform for All Kinds of Live Streaming'}
          heading={
            <>
              Frequently Asked Questions
            </>
          }
          faqs={[
            {
              question: "What is the difference between Live Video Streaming and ZOOM or any other Video Conference ??",
              answer:"<p>It should be rather put as Live Streaming V/s Web conferencing tools.</p><p> Depending on the software, most video conferencing platforms or online meeting tools have a limit on the number of participants that are able to join the event. Livestreaming, on the other hand, creates a more engaging and compelling experience, allowing you to reach, impact – and scale – to millions. Livestreaming also allows you to use polling, chat, and other tools to engage with your audience in real time.</p><p> Video conferencing tools will work if you’re looking for a continuous back and forth real-time voice conversation with a limited number of people, with virtually no audio or video delay. If you don’t need to add graphics or other production elements, these solutions do the job.</p>"
            },
            {
              question: "How Live Streaming Works ?",
              answer:
                "Live video streaming often requires a camera to capture the content, an encoder to compress the data, a media server to process the data and convert it when required, a content delivery network to distribute the live video across the world, and an internet-connected device for viewing / playback. While a lot goes into getting that live stream from capture to playback, we designed our software, services, and platform to help you along the way."
            },
            {
              question: "What are the Benefits of Live Streaming ?",
              answer:
                "Live streaming is one of the most flexible and authentic ways to connect end users in real time. With exploding adoption rates for online contents, proliferating use cases, and continuously improving internet bandwidth across the globe, the video streaming is now intruding in every day life like never before. Live Streaming technology has made its significant positions in all walks of life from simple coaching to defence grade body cams, how are you going to use this technology next ??"
            },
            {
              question: "What is the cost of Aurum Live Streaming Services ?",
              answer:
                "Aurum Live Streaming services are lowest in the industry. Pricing is highly dependent on the volume of the usage, contact our sales team for the best quote."
            }
          ]}
        />
        <PreFooter fromPage = "Live Video Streaming" formheading = "Live Streaming Increases Amplifies User Engagement and Revenues" formsubheading = "Book Our Free Discussion Session Today - " heightlightText="How to Start Live Streaming and Grow Your Business"/>
      </AnimationRevealPage>
      <Footer />
   </>
  );
}
